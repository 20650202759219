import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { graphql } from 'gatsby';

import Bio from '../components/bio';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { rhythm } from '../utils/typography';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const About = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata.title;
    const { social, author } = data.site.siteMetadata;
    return (
        <Layout location={location} title={siteTitle} social={social} author={author}>
            <SEO title="About me" description="About me" />
            <div
                css={css({
                    marginLeft: `auto`,
                    marginRight: `auto`,
                    maxWidth: rhythm(24),
                })}
            >
                <article>
                    <img css={css(mobileShowStyle)} src="/hippo.gif" alt="hippo" />
                    <header>
                        <h1
                            css={css({
                                marginTop: rhythm(1),
                                marginBottom: rhythm(1),
                            })}
                        >
                            About me
                        </h1>
                    </header>
                    <div css={css(containerStyle)}>
                        <div css={css(descStyle)}>
                            <p>我喜歡小動物，</p>
                            <p>喜歡與毛小孩在一起的每分每秒，</p>
                            <p>想把點點滴滴透過畫圖全都記錄下來、、、</p>
                            <p>目前正在自學2D手繪動畫，發現畫畫能力不太足，</p>
                            <p>只好練習練習再練習，努力朝著自己的大秘寶前進！</p>
                        </div>
                        <div>
                            <img css={css(mobileHiddenStyle)} src="/hippo.gif" alt="hippo" />
                        </div>
                    </div>
                    <footer>
                        <Bio />
                    </footer>
                </article>
            </div>
        </Layout>
    );
};

About.propTypes = {
    data: PropTypes.object,
    location: PropTypes.object,
};

export default About;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
                author {
                    name
                    summary
                }
                social {
                    facebook
                    twitter
                    instagram
                }
            }
        }
    }
`;

const containerStyle = {
    marginTop: rhythm(1),
    marginBottom: rhythm(1),
    display: 'flex',
    '& p': {
        marginTop: rhythm(1 / 2),
        marginBottom: rhythm(1 / 2),
    },
};

const mobileShowStyle = {
    display: 'none',
    '@media only screen and (max-width: 489px)': {
        display: 'block',
    },
};

const mobileHiddenStyle = {
    display: 'block',
    '@media only screen and (max-width: 489px)': {
        display: 'none',
    },
};

const descStyle = {
    flex: '0 0 auto',
};
